<template>
    <template-base width="95">
        <div class="card-header">
            <h1>Digite seu e-mail</h1>
            <span class="mt-2">Um link para a redefinição da senha será enviado ao seu e-mail</span>
        </div>
        <!--<LoginForm ref="login" />-->
        <div class="card-body">
            <form @submit.prevent="enviar">
                <div class="form-group">
                    <label for="userEmail">E-mail</label>
                    <input class="form-control tcs-input " id="userEmail" v-model="email" type="email" required
                        :disabled="isBusy" />
                </div>
                <!--<hr class="my-4" />-->
                <div class="d-flex justify-content-between">
                    <router-link replace :to="{ name: 'login' }" class="align-self-center" data-cy="Voltar">Voltar</router-link>
                    <div class="text-right">
                        <button type="submit" class="btn btn-success lg" v-if="!isBusy">
                            Enviar
                        </button>
                        <button type="submit" class="btn btn-success lg" disabled="disabled" v-else>
                            <span class="spinner-border x-spinner-border-sm" role="status" aria-hidden="true"></span>
                            &nbsp;Enviando...
                        </button>
                    </div>
                </div>
                <transition name="fade">
                    <div class="mt-1 d-flex text-danger" v-if="error">
                        <x-circle-icon class="mr-2" />
                        <div>{{error}}</div>
                    </div>
                    <div class="mt-1 d-flex text-success" v-else-if="success">
                        <check-circle-icon class="mr-2" />
                        <div>{{mensagem}}</div>
                    </div>
                </transition>
            </form>
        </div>
    </template-base>
</template>
<script>
import TemplateBase from '../templates/Base'
import axios from 'axios'
import api from '@/api.js'

export default {
    components: {
        TemplateBase,
    },
    data () {
        return {
            email: '',
            isBusy: false,
            error: '',
            success: false,
            mensagem: '',
        }
    },
        methods: {
        async enviar () {
            this.isBusy = true;
            this.error = '';
            this.success = false;
            try {
                let res = await axios.post(api.v1.password.forgot, { email: this.email });
                this.success = true;
                this.error = res.data.error;
                this.mensagem = res.data.mensagem;
                this.isBusy = false;
            } catch (e) {
                this.isBusy = false;
                this.error = e.toString() || 'Erro desconhecido, por favor tente novamente.';
            }
        }
    },
};
</script>
<style scoped>
.spinner-border.x-spinner-border-sm {
    width: 1.1rem;
    height: 1.1rem;
    border-width: 0.2em;
}

@media screen and (min-width: 790px) {
    .card {
        max-width: 55%
    }
}
</style>
